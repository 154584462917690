import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import ThumbnailItem from './ThumbnailItem';
import CoverItem from './CoverItem';

const Search = ({ query }) => {
  const [queryField, setQueryField] = useState(query);
  const [results, setResults] = useState([]);
  const input = useRef();

  useEffect(() => {
    input.current.focus();
  }, []);

  useEffect(() => {
    setResults(
      window.searchIndex
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => {
          return window.searchIndex.documentStore.getDoc(ref);
        })
    );
  }, [query]);

  const media = results.filter((page) => page.type === 'sermon');
  const articles = results.filter((page) => page.type === 'article');

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        navigate(`/search?query=${encodeURIComponent(queryField)}`);
      }}
    >
      <Box mb={4}>
        <TextField
          id="outlined-basic"
          inputRef={input}
          variant="outlined"
          value={queryField}
          onChange={({ target }) => setQueryField(target.value)}
          fullWidth
          placeholder="Search all media and articles"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton type="submit">
                  <ArrowRightIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {query && media.length === 0 && articles.length === 0 && (
        <Typography variant="body1">No results to display.</Typography>
      )}

      {media.length > 0 && (
        <Box mb={4}>
          <Box mb={2}>
            <Typography variant="h4">Media</Typography>
          </Box>

          <Grid container spacing={4}>
            {media.map(({ id, title, author, date, image, path }) => (
              <Grid item key={id} xs={12} md={6}>
                <ThumbnailItem
                  title={title}
                  subtext={author}
                  timestamp={date}
                  image={image}
                  to={path}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {articles.length > 0 && (
        <Box mb={4}>
          <Box mb={2}>
            <Typography variant="h4">Articles</Typography>
          </Box>

          <Grid container spacing={4}>
            {articles.map(({ id, title, author, date, image, path }) => (
              <Grid item key={id} xs={12} md={4}>
                <CoverItem
                  title={title}
                  subtext={author}
                  timestamp={date}
                  image={image}
                  to={path}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </form>
  );
};

Search.propTypes = {
  query: PropTypes.string,
};

Search.defaultProps = {
  query: '',
};

export default Search;
