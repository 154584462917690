import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import queryString from 'query-string';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Search from '../components/Search';

const Page = ({ location }) => {
  const { query } = queryString.parse(location.search);

  return (
    <Layout>
      <SEO title="Search" />

      <Box mb={8}>
        <Search query={query} />
      </Box>
    </Layout>
  );
};

Page.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Page;
